import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  useWindowDimensions,
  ActivityIndicator,
  TouchableOpacity,
  Image,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { useMediaQuery } from "react-responsive";
import { Text, Button, Tag, Card, Input, Table } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Ionicons } from "@expo/vector-icons";
import { motion } from "framer-motion";
import JsxParser from "react-jsx-parser";
import RemoteImage from "../../../../../components/RemoteImage";
import Success from "@components/SuccessComponent/SuccessComponent";

const Goal = ({ order }) => {
  const {
    state: { plan, tabs },
  } = useContext(PlanContext);
  const scrollRef = useRef(null);
  const [animating, setAnimating] = useState(false);

  const goalContent = tabs[order];

  const successData = [
    {
      order: 3,
      componentType: 5,
      headerImage:
        "https://images.unsplash.com/photo-1552674605-db6ffd4facb5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80",
      header: `"User Activity Report"`,
      subHeader: `"INCOMING HEALTH DATA"`,
      data: [
        {
          component: "header",
          title: `"ACTIVITY OVERVIEW"`,
        },
        {
          component: "progress",
          title: `"Oxygen Level:" + " " + "94/100"`,
          value: `94`,
          max: `100`,
        },
        {
          component: "gradient-stat",
          key: `"AVG. HEART RATE"`,
          value: `72`,
          unit: `"BPM"`,
        },
        {
          component: "checklist",
          data: [
            {
              key: "Max Heart Rate (bpm)",
              value: `128`,
              type: "num",
            },
            {
              key: "Exercise Duration (min)",
              value: `74`,
              type: "num",
            },
            {
              key: "Calories Burned (cal)",
              value: `566`,
              type: "num",
            },
          ],
        },
      ],
    },
    {
      componentType: 3, // Data Visualization
      headerIcon: "bar-chart",
      header: `"Historical Health Data"`,
      subHeader: `"Activity Data"`,
      successLabel: `"Health Data Filtered"`,
      dataLabels: ["Avg Heart Rate", "Max Heart Rate", "Min Heart Rate"],
      data: [
        {
          name: "6/04",
          "Avg Heart Rate": 70,
          "Max Heart Rate": 88,
          "Min Heart Rate": 58,
        },
        {
          name: "6/05",
          "Avg Heart Rate": 65,
          "Max Heart Rate": 125,
          "Min Heart Rate": 62,
        },
        {
          name: "6/06",
          "Avg Heart Rate": 68,
          "Max Heart Rate": 155,
          "Min Heart Rate": 64,
        },
        {
          name: "6/07",
          "Avg Heart Rate": 76,
          "Max Heart Rate": 134,
          "Min Heart Rate": 65,
        },
        {
          name: "6/08",
          "Avg Heart Rate": 74,
          "Max Heart Rate": 92,
          "Min Heart Rate": 60,
        },
        {
          name: "6/09",
          "Avg Heart Rate": 75,
          "Max Heart Rate": 96,
          "Min Heart Rate": 54,
        },
        {
          name: "6/10",
          "Avg Heart Rate": 68,
          "Max Heart Rate": 144,
          "Min Heart Rate": 55,
        },
      ],
    },
  ];

  const componentVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  return (
    <View style={styles.container}>
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <View style={{ width: "100%" }}>
          <Text
            style={{
              color: plan.company.headingColor,
              marginBottom: -10,
              fontFamily:
                plan.company.headingFont != null
                  ? plan.company.headingFont
                  : null,
            }}
            h1
          >
            Mission
          </Text>
          <Text
            style={{
              color: plan.company.darkColor,
              marginBottom: -10,
              fontFamily:
                plan.company.headingFont != null
                  ? plan.company.headingFont
                  : null,
            }}
            h1
          >
            {goalContent.title}
          </Text>
          <View style={{ marginVertical: 15 }} />
          <Text style={{ color: plan.company.darkColor }} font="16px">
            {goalContent.description}
          </Text>
          <View style={{ marginVertical: 10 }} />
          <View style={{ alignSelf: "flex-start" }}>
            <Button
              style={{ fontWeight: "bold" }}
              type="secondary"
              onClick={() =>
                // lastComp.current.scrollIntoView({
                //   behavior: "smooth",
                // })
                {
                  setAnimating(true);
                }
              }
            >
              Start Animation
            </Button>
          </View>
        </View>
      </View>
      <View style={{ marginVertical: 20 }} />
      <View style={styles.scrollContainer}>
        <ScrollView
          ref={scrollRef}
          horizontal
          contentContainerStyle={styles.animationContainer}
        >
          <motion.div
            style={{
              alignSelf: "center",
              height: 500,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <View
              style={[
                styles.databaseView,
                { padding: 30, justifyContent: "center", alignSelf: "center" },
              ]}
            >
              <Image
                source={require("../../../../../../assets/images/lock-dynamic-premium.png")}
                style={{
                  height: 200,
                  width: 200,
                  alignSelf: "center",
                }}
              />
              <View style={{ marginVertical: 10 }} />
              <TouchableOpacity
                style={{
                  backgroundColor: plan.company.darkColor,
                  width: "100%",
                  paddingVertical: 8,
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: 5,
                  flexDirection: "row",
                  paddingHorizontal: 15,
                }}
              >
                <Ionicons
                  name="ios-checkmark-circle"
                  size={24}
                  color={plan.company.lightColor}
                />
                <Text style={{ color: plan.company.lightColor }} small b>
                  Authentication
                </Text>
                <View />
              </TouchableOpacity>
            </View>
          </motion.div>

          <motion.div
            variants={componentVariants}
            initial="hidden"
            animate={animating ? "visible" : "hidden"}
            style={{ alignSelf: "center" }}
          >
            <View style={styles.nextBorder} />
          </motion.div>

          <motion.div
            variants={componentVariants}
            initial="hidden"
            animate={animating ? "visible" : "hidden"}
            style={{ alignSelf: "center", height: 500 }}
            transition={{ delay: 0.2 }}
          >
            <Success data={successData[0]} />
          </motion.div>

          <motion.div
            variants={componentVariants}
            initial="hidden"
            animate={animating ? "visible" : "hidden"}
            style={{ alignSelf: "center" }}
            transition={{ delay: 1.2 }}
          >
            <View style={styles.nextBorder} />
          </motion.div>

          <motion.div
            onAnimationComplete={() => {
              if (animating)
                scrollRef.current.scrollBy({
                  top: 0,
                  left: +1000,
                  behavior: "smooth",
                });
            }}
            variants={componentVariants}
            initial="hidden"
            animate={animating ? "visible" : "hidden"}
            transition={{ delay: 1.2 }}
            style={{ alignSelf: "center", width: 400, height: 500 }}
          >
            <Success data={successData[1]} height={500} />
          </motion.div>
        </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  scrollContainer: {
    borderWidth: 1,
    borderColor: "lightgrey",
    borderRadius: 10,
  },
  animationContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    minWidth: "100%",
    paddingVertical: 40,
    paddingHorizontal: 25,
  },
  databaseView: {
    padding: 30,
    alignSelf: "center",
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 15,

    elevation: 9,
  },
  nextBorder: {
    height: 0,
    width: 75,
    borderTopWidth: 1,
    borderTopColor: "grey",
    borderStyle: "dashed",
    alignSelf: "center",
    marginHorizontal: 20,
  },
  // ...JSON.parse(loadedStyles),
});

export default Goal;

import createDataContext from "./createDataContext";
import serverApi from "../api/server";
import * as RootNavigation from "../RootNavigation";
import * as Sentry from "sentry-expo";
import _ from "lodash";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { toast } from "react-toastify";
import generateGradient from "../functions/generateGradient";

const onErr = async (err, route) => {
  console.log(`${route} error`);
  console.log(err);
  const userIdentification = await AsyncStorage.getItem("userId");
  Sentry.Browser.configureScope(function (scope) {
    scope.setExtra("function_name", route);
    scope.setExtra("id", userIdentification);
    Sentry.Browser.captureException(err);
  });
  toast.error("Having trouble connecting to the server... Try again in a few.");
};

const calcTabs = (content) => {
  const tabs = [];

  if (content.letter != null && content.letter != "") {
    tabs.push({
      order: 0,
      type: 0,
      title: "Overview",
    });
  }

  for (let i = 0; i < content.length; i++) {
    tabs.push({
      order: tabs.length,
      type: 1, // GOAL
      title: content[i].goalTitle,
      content: content[i].goal,
    });

    const goalOrder = tabs.length - 1;
    let stepOrder = 0;

    const stepArr = content[i].steps;
    for (let ii = 0; ii < stepArr.length; ii++) {
      tabs.push({
        order: tabs.length,
        type: 2, // STEP
        goalOrder: goalOrder,
        stepNumber: stepOrder,
        title: stepArr[ii].stepTitle,
        description: stepArr[ii].stepDescription,
        content: stepArr[ii].step,
      });
      stepOrder++;
    }
  }

  tabs.push({
    order: tabs.length,
    type: 3, // NEXT STEPS
    title: "Next Steps",
    content: content.nextSteps,
  });

  return tabs;
};

const genGradients = (number) => {
  let gradients = [];
  for (let i = 0; i < number; i++) {
    gradients.push(generateGradient());
  }
  return gradients;
};

const planReducer = (state, action) => {
  switch (action.type) {
    case "fetch_plan":
      return {
        ...state,
        plan: action.payload,
        tabs: calcTabs(action.payload.content),
        gradients: genGradients(calcTabs(action.payload.content).length),
      };
    case "select_tab":
      return {
        ...state,
        selectedTab: action.payload,
      };
    case "reset":
      return {
        plan: null,
        selectedTab: 0,
      };
    default:
      return state;
  }
};

const fetchPlan = (dispatch) => async (planId) => {
  try {
    const response = await serverApi.post("/plan", { planId: planId });
    dispatch({ type: "fetch_plan", payload: response.data });
  } catch (err) {
    onErr(err, "fetchPlan");
  }
};

const selectTab = (dispatch) => async (tab) => {
  dispatch({ type: "select_tab", payload: tab });
};

const resetPlanContext = (dispatch) => async () => {
  dispatch({ type: "reset" });
};

export const { Provider, Context } = createDataContext(
  planReducer,
  {
    fetchPlan,
    selectTab,
    resetPlanContext,
  },
  {
    plan: {
      company: {
        _id: "example-terra-id",
        name: "Terra",
        logo: "https://i.ibb.co/XsdWqvn/terra-logo-white.png",
        email: "SUPPORT@TRYTERRA.CO",
        lightColor: "#60A5FA",
        darkColor: "black",
        headingColor: "#60A5FA",
        fontImport: "Space Grotesk:300,400,800",
        bodyFont: "Space Grotesk",
        headingFont: "Space Grotesk:300",
      },
      prospectCompany: "Mentor360",
      prospectName: "John",
      apiKey: "sk_SRedsgh438kx9BWLCjLk3cVB",
      letter:
        "We’re excited to show you how Terra can work with Mentor360 to improve coaching with in-depth health data.\nWe’ve gone ahead and mapped out a potential integration plan according to your needs.\nOur goal is to help you visualize how Terra fits with your use case.\nBest,\nThe Terra Team",
      prospectLogo:
        "https://www.mentor360.com/wp-content/themes/mentor/dist/images/mentor_logo-dark.svg",
      // prospectIcon:
      //   "https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,f_auto,q_auto:eco,dpr_1/om6pejoyjy22x28dqika",

      content: [
        {
          goalTitle: "Improve Coaching with Health Data",
        },
      ],
    },
    tabs: [
      {
        order: 0,
        type: 0,
        title: "Overview",
      },
      {
        order: 1,
        type: 1,
        title: "Improve Coaching with Health Data",
        description: `The Terra API makes it easy to add health data to improve the coaching experience of your app. Let your users experience a frictionless onboarding flow with our connect widget, and then you'll have access to realtime health data to equip your coaches and make content recommendations.`,
      },
      {
        order: 2,
        type: 2,
        goalOrder: 1,
        stepNumber: 1,
        title: "Connect User's Wearable",
        dbTitle: "connect",
        description: `Try out the Terra connect process! We have the frontend UI widgets to get your connect flow up and running in minutes.`,
        content: {
          type: 2,
          objectName: "Wearable",
          itemName: `localDatabase.connect.name + " " + "ID.3"`,
          logoUrl:
            "https://www.mentor360.com/wp-content/themes/mentor/dist/images/mentor_logo-dark.svg",
          data: [
            {
              id: "id_garmin",
              parent_name: "Garmin",
              name: "Garmin",
              logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c7/Garmin_logo.svg/2560px-Garmin_logo.svg.png",
            },
            {
              id: "id_withings",
              parent_name: "Withings",
              name: "Withings",
              logo: "http://media-cdn.withings.com/press/logo_withings_black.png",
            },
            {
              id: "id_fitbit",
              parent_name: "Fitbit",
              name: "Fitbit",
              logo: "https://1000logos.net/wp-content/uploads/2017/09/Fitbit-Logo-2007.jpg",
            },
            {
              id: "id_google_fit",
              parent_name: "Google Fit",
              name: "Google Fit",
              logo: "https://lh3.googleusercontent.com/ir2-W48gf2uIorNfXw4UDmK1mbq0g79vqe-3JVz9urSlhKQjBT58o57ENqtZ71MovujW10qrVe-mhpiic_Dsrg=w320",
            },
            {
              id: "id_oura",
              parent_name: "Oura",
              name: "Oura",
              logo: "https://images.squarespace-cdn.com/content/v1/5b3694dd50a54f1fd0ce1a82/1622141743370-N9SKH7K4XCX4WBI4ZZAS/SandHillAngels-Portfolio-Oura.png",
            },
            {
              id: "id_wahoo",
              parent_name: "Wahoo",
              name: "Wahoo",
              logo: "https://digital.hbs.edu/platform-rctom/wp-content/uploads/sites/4/2016/11/Wahoo-Fitness.jpg",
            },
            {
              id: "id_peloton",
              parent_name: "Peloton",
              name: "Peloton",
              logo: "https://logos-world.net/wp-content/uploads/2021/02/Peloton-Cycle-Logo.png",
            },
            {
              id: "id_zwift",
              parent_name: "Zwift",
              name: "Zwift",
              logo: "https://tukuz.com/wp-content/uploads/2020/09/zwift-inc-logo-vector.png",
            },
            {
              id: "id_training_peaks",
              parent_name: "Training Peaks",
              name: "Training Peaks",
              logo: "https://images.squarespace-cdn.com/content/v1/5c515162266c071e121f6b80/1590753914033-NQ1LI2RBDN3A5MLHVLCM/logo-trainingpeaks-stacked-web.jpg",
            },
            {
              id: "id_freestyle_libre",
              parent_name: "FreeStyle Libre",
              name: "FreeStyle Libre",
              logo: "https://www.freestylelibre.co.uk/content/dam/adc/fsl/images/global/en/desktopImages/libre-logo-horizontal.png",
            },
            {
              id: "id_polar",
              parent_name: "Polar",
              name: "Polar",
              logo: "https://www.polar.com/og-polar-logo.jpg",
            },
            {
              id: "id_suunto",
              parent_name: "Suunto",
              name: "Suunto",
              logo: "https://seekvectorlogo.net/wp-content/uploads/2019/04/suunto-vector-logo.png",
            },
            {
              id: "id_eight_sleep",
              parent_name: "Eight Sleep",
              name: "Eight Sleep",
              logo: "https://images.squarespace-cdn.com/content/v1/551ae292e4b0a45a7a81e42f/1572278486299-XGCQFU2YZ9IES9JIFO6T/Eight_logo-02.png?format=1000w",
            },
            {
              id: "id_apple",
              parent_name: "Apple Health",
              name: "Apple Health",
              logo: "https://cdn.icon-icons.com/icons2/2699/PNG/512/apple_logo_icon_168588.png",
            },
            {
              id: "id_samsung_health",
              parent_name: "Samsung Health",
              name: "Samsung Health",
              logo: "https://img.global.news.samsung.com/uk/wp-content/uploads/2018/05/SAMSUNG-HEALTH-LOGO.jpg",
            },
            {
              id: "id_tempo",
              parent_name: "Tempo",
              name: "Tempo",
              logo: "https://ms-f7-sites-01-cdn.azureedge.net/docs/stories/810722-tempo-health-provider-azure-kinect/resources/8d850e1e-88a1-4be4-a5bb-8f2b2fb8999d/1239287276166700414_1239287276166700414",
            },
          ],
        },
      },
      {
        order: 3,
        type: 2,
        goalOrder: 1,
        stepNumber: 2,
        title: "Receive Health Data",
        dbTitle: "health",
        description: `The Terra API is Webhook-based! Once your user connects their wearable, Terra streams health data to your application throughout the day whenever it is available from a user.`,
        content: {
          type: 3,
          preloadKey: "connect",
          botDetails: [
            {
              title: "Establishing Wearable Connection",
              sub: [
                {
                  key: "Status",
                  value: "Completed",
                },
                {
                  key: "Wearable ID",
                  value: "3829382",
                },
              ],
            },
            {
              title: "Pinging Remote Server",
              sub: [
                {
                  key: "Status",
                  value: "Verified",
                },
              ],
            },
            {
              title: "Parsing and Normalizing Data",
              sub: [
                {
                  key: "Status",
                  value: "Completed",
                },
              ],
            },
          ],
          payload: {
            oxygen_data: {
              saturation_percentage: 95,
              max_volume_ml_per_min_per_kg: 45,
              saturation_samples: [
                {
                  timestamp: "2021-03-21T04:00:00-07:00",
                  percentage: 97,
                },
              ],
            },
            energy_data: {
              energy_kilojoules: 100,
              energy_planned_kilojoules: 100,
            },
            strain_data: {
              strain_level: 57,
            },
            power_data: {
              avg_watts: 210.0,
              power_samples: [
                {
                  timestamp: "2021-03-21T04:00:00-07:00",
                  watts: 210.0,
                  timer_duration_seconds: 10,
                },
                {
                  timestamp: "2021-03-21T04:05:00-07:00",
                  watts: 210.0,
                  timer_duration_seconds: 20,
                },
              ],
              max_watts: 220.1,
            },
            MET_data: {
              num_moderate_intensity_minutes: 213,
              num_low_intensity_minutes: 13,
              avg_level: 1.425,
              samples: [
                {
                  timestamp: "2021-03-21T04:10:00-07:00",
                  level: 0.9,
                },
                {
                  timestamp: "2021-03-21T04:02:00-07:00",
                  level: 0.9,
                },
              ],
              num_high_intensity_minutes: 423,
              num_inactive_minutes: 40,
            },
            polyline_map_data: {
              summary_polyline:
                "amdbIts}EDCDWRJF?r@[VGV?RFH?bAYn@?r@F`B?TBVCfBC~@Bx@Qb@Qb@MhACFBT?HA\\DtAIz@CZEZFh@ELETDp@Xv@IVUNC\\M~A{@f@yADCVyANa@JcAFsBGcAxCm@f@ZHNxAbAv@z@p@\\r@t@^VtAnA|@f@\\PbAZd@@t@PtC^HBBR^FjAZjBn@ZDb@Rd@Jz@\\ZTd@l@n@jATf@Tl@Rt@NbATjBVjDD~@MVDr@@`DG`DOlB_@tBu@|Bi@lAOX_BdC_@\\qA|@s@`@{Ar@}CnBe@b@e@p@qBvD]h@g@j@[n@e@vB{@r@e@h@gApDUf@g@zA?P@j@Vt@^pBN|Ad@dCPlBThAj@dBZx@z@|AZZj@f@hBlB|BzBn@d@p@`@bA^h@PvB?x@ExBDxC@{ALaCHmAEsCDo@IqAe@SKsAeAkAsAcBwAuBaCUa@Ym@i@m@[y@Oy@[iAG]g@wEIWOqCKs@Qy@Wk@FcA~@_BfAyDXi@`@a@\\WRi@^wA^gAZa@`BaD\\i@n@y@hFmD|D_Cb@[XYz@qA^w@Xy@Vc@XaALs@Am@Me@G_@\\sB@uBCqB@e@?RGt@HdCAdAIvAQzAS~@Uf@_AdAa@p@Wl@m@dA?FLAT[j@aAdAuBRw@\\y@F[VyCDiD?aBDm@Jy@Nq@?MI}@C}@[eCaAeEQm@k@mAe@m@WMo@COGHAfALXLx@dAt@zAPf@T~@\\`CRdC?HONKAKIOc@Ow@s@}Di@eBWe@[a@q@u@e@QcB_@u@MyBq@eB]g@Gc@A}Ae@GU@i@DCVAtALfA^BFE~@@JDJlBd@CEIEe@QgAOiBk@e@AiCm@a@MaAe@",
            },
            heart_rate_data: {
              summary: {
                user_hr_max: 190,
                min_hr: 60,
                avg_hr_variability: 65,
                avg_hr: 70,
                resting_hr: 60,
                max_hr: 80,
              },
              detailed: {
                hrv_samples: [
                  {
                    timestamp: "2021-03-21T04:01:00-07:00",
                    hrv: 65,
                  },
                  {
                    timestamp: "2021-03-21T04:02:00-07:00",
                    hrv: 65,
                  },
                ],
                hr_samples: [
                  {
                    timestamp: "2021-03-21T04:01:00-07:00",
                    bpm: 70,
                  },
                  {
                    timestamp: "2021-03-21T04:02:00-07:00",
                    bpm: 71,
                  },
                ],
              },
            },
            active_durations_data: {
              activity_seconds: 120,
              low_intensity_seconds: 90,
              rest_seconds: 1230,
              num_continuous_inactive_periods: 4,
              inactivity_seconds: 49,
              activity_levels_samples: [
                {
                  timestamp: "2021-03-21T04:00:00-07:00",
                  activity_level: 2,
                },
                {
                  timestamp: "2021-03-21T04:02:00-07:00",
                  activity_level: 2,
                },
              ],
              moderate_intensity_seconds: 500,
              vigorous_intensity_seconds: 700,
            },
            movement_data: {
              normalized_speed_metres_per_second: 4.05,
              avg_torque_newton_metres: 8,
              avg_pace_minutes_per_kilometre: 5.12,
              max_pace_minutes_per_kilometre: 6.05,
              max_speed_metres_per_second: 4.27,
              avg_cadence: 78,
              max_cadence: 78,
              avg_velocity_metres_per_second: 3,
              max_torque_newton_metres: 10,
              avg_speed_metres_per_second: 3.26,
              max_velocity_metres_per_second: 4,
              speed_samples: [
                {
                  timestamp: "2021-03-21T04:00:00-07:00",
                  cadence: 1.3,
                  timer_duration_seconds: 1,
                },
                {
                  timestamp: "2021-03-21T04:02:00-07:00",
                  cadence: 4.4,
                  timer_duration_seconds: 2,
                },
              ],
              speed_samples: [
                {
                  timestamp: "2021-03-21T04:00:00-07:00",
                  speed: 4.3,
                  timer_duration_seconds: 1,
                },
                {
                  timestamp: "2021-03-21T04:02:00-07:00",
                  speed: 5.4,
                  timer_duration_seconds: 2,
                },
              ],
            },
            metadata: {
              city: "London",
              type: 17,
              end_time: "2021-03-21T04:50:00-07:00",
              start_time: "2021-03-21T04:00:00-07:00",
              country: "United Kingdom",
              name: "Spinning",
              summary_id: "String",
              upload_type: 1,
              state: "None",
            },
            lap_data: {
              laps: [
                {
                  start_time: "2021-03-21T04:00:00-07:00",
                  calories: 40.0,
                  distance: 342.0,
                },
              ],
            },
            TSS_data: {
              samples: [
                {
                  intensity_factor_planned: 80,
                  intensity_factor_actual: 89.94437,
                  method: "HR",
                  planned: 80.0,
                  actual: 89.9443,
                  normalized_power_watts: 200,
                },
              ],
            },
            device_data: {
              software_version: "1.12.36",
              activation_timestamp: "2022-02-13T03:18:57+00:00",
              manufacturer: "Suunto",
              serial_number: "1234567898765",
              name: "Suunto Spartan Sport Wrist HR",
              hardware_version: "LankkuD",
              other_devices: [],
            },
            work_data: {
              work_in_kilojoules: "None",
            },
            distance_data: {
              summary: {
                floors_climbed: 1000,
                swimming: {
                  pool_length_metres: 25,
                  num_laps: 35,
                  num_strokes: 493,
                },
                elevation: {
                  gain_actual_metres: 28.7,
                  avg_metres: "Number",
                  loss_actual_metres: 5.0,
                  max_metres: 40.0,
                  gain_planned_metres: 36.7,
                  min_metres: 28.0,
                },
                distance_metres: 1203,
                steps: 10200,
              },
              detailed: {
                elevation_samples: [
                  {
                    timestamp: "2021-03-21T04:00:00-07:00",
                    elev_metres: 109.4000244140625,
                    timer_duration_seconds: 20,
                  },
                  {
                    timestamp: "2021-03-21T04:07:00-07:00",
                    elev_metres: 149.4123244140625,
                    timer_duration_seconds: 30,
                  },
                ],
              },
            },
            calories_data: {
              net_intake_calories: 2300,
              net_activity_calories: 700,
              BMR_calories: 2100,
              total_burned_calories: 2800,
            },
            position_data: {
              start_pos_lat_lng: [-0.061000000000000006, 51.531135],
              position_samples: [
                {
                  timestamp: "2021-03-21T04:00:00-07:00",
                  coords_lat_lng: [51.053232522681355, -114.06880217604339],
                  timer_duration_seconds: 20,
                },
                {
                  timestamp: "2021-03-21T04:05:00-07:00",
                  coords_lat_lng: [51.053232522681355, -114.06880217604339],
                  timer_duration_seconds: 10,
                },
              ],
              centre_pos_lat_lng: [-0.04478833333333333, 51.53568],
              end_pos_lat_lng: [-0.04478833333333333, 51.53568],
            },
          },
          success: {
            order: 3,
            componentType: 5, // PhoneDisplay
            headerImage:
              "https://images.unsplash.com/photo-1552674605-db6ffd4facb5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80",
            header: `"User Activity Report"`,
            subHeader: `"INCOMING HEALTH DATA"`,
            data: [
              {
                component: "header",
                title: `"ACTIVITY OVERVIEW"`,
              },
              {
                component: "progress",
                title: `"Oxygen Level: " + localDatabase.health.oxygen_data.saturation_percentage + "%"`,
                value: `localDatabase.health.oxygen_data.saturation_percentage`,
                max: `100`,
              },
              {
                component: "gradient-stat",
                key: `"AVG. HEART RATE"`,
                value: `localDatabase.health.heart_rate_data.summary.avg_hr`,
                unit: `"BPM"`,
              },
              {
                component: "checklist",
                data: [
                  {
                    key: "Max Heart Rate (bpm)",
                    value: `localDatabase.health.heart_rate_data.summary.user_hr_max`,
                    type: "num",
                  },
                  {
                    key: "Exercise Duration (min)",
                    value: `(localDatabase.health.active_durations_data.activity_seconds/60).toFixed(2)`,
                    type: "num",
                  },
                  {
                    key: "Calories Burned (cal)",
                    value: `localDatabase.health.calories_data.total_burned_calories`,
                    type: "num",
                  },
                ],
              },
            ],
          },
        },
      },
      {
        order: 4,
        type: 2,
        goalOrder: 1,
        stepNumber: 3,
        title: "Historical Pattern Analysis",
        dbTitle: "charge",
        description: `While the default behavior is to be sent data via webhooks, Terra also supports HTTP endpoints for fetching historical data, like getting a user's activity history.`,
        content: {
          type: 0,
          firstParam: "type",
          lastParam: "dob",
          codeSnippet: `curl https://api.tryterra.co/v2/activity/
          -X GET
          -H "dev-id: YOUR_DEV_ID"
          -H "x-api-key: your_api_key"
          -H "Content-Type: application/json"
          -d '{
            "user_id": "f7a4346d"
            "startDate": "2022-06-04",
            "endDate": "2022-06-10",
          }'`,
          requestFields: [
            {
              key: "user_id",
              value: "f7a4346d",
              type: 1,
              desc: "User ID",
              preloadKey: "connect.id",
              preloadDesc: "connect.parent_name",
            },
            {
              key: "startDate",
              value: "2022-06-04",
              type: 0,
              desc: "Earliest Date to Fetch",
            },
            {
              key: "endDate",
              value: "2022-06-10",
              type: 0,
              desc: "Latest Date to Fetch",
            },
          ],
          botDetails: [
            {
              title: "Accessing User Wearable",
              sub: [
                {
                  key: "Status",
                  value: "Completed",
                },
              ],
            },
            {
              title: "Running Query by Dates",
              sub: [
                {
                  key: "Status",
                  value: "Completed",
                },
              ],
            },
            {
              title: "Analyzing Data",
            },
            {
              title: "Parsing and Filtering",
            },
          ],
          payload: {
            data: [
              {
                kw: {
                  min: 0,
                  max: 78,
                  mean: 61,
                },
                kwhSum: 120,
                price: {
                  min: 13.8,
                  max: 14.4,
                  mean: 14.1,
                },
                nonSmartPrice: {
                  min: 12.9,
                  max: 16.7,
                  mean: 14.8,
                },
                costSum: 3.14,
                nonSmartCostSum: 4.21,
                estimatedSavings: 1.07,
                date: "2021-01-19T09:37:36.845Z",
              },
            ],
          },
          success: {
            componentType: 3, // Data Visualization
            headerIcon: "bar-chart",
            header: `"Historical Health Data"`,
            subHeader: `"Activity Data"`,
            successLabel: `"Health Data Filtered"`,
            dataLabels: ["Avg Heart Rate", "Max Heart Rate", "Min Heart Rate"],
            data: [
              {
                name: "6/04",
                "Avg Heart Rate": 70,
                "Max Heart Rate": 88,
                "Min Heart Rate": 58,
              },
              {
                name: "6/05",
                "Avg Heart Rate": 65,
                "Max Heart Rate": 125,
                "Min Heart Rate": 62,
              },
              {
                name: "6/06",
                "Avg Heart Rate": 68,
                "Max Heart Rate": 155,
                "Min Heart Rate": 64,
              },
              {
                name: "6/07",
                "Avg Heart Rate": 76,
                "Max Heart Rate": 134,
                "Min Heart Rate": 65,
              },
              {
                name: "6/08",
                "Avg Heart Rate": 74,
                "Max Heart Rate": 92,
                "Min Heart Rate": 60,
              },
              {
                name: "6/09",
                "Avg Heart Rate": 75,
                "Max Heart Rate": 96,
                "Min Heart Rate": 54,
              },
              {
                name: "6/10",
                "Avg Heart Rate": 68,
                "Max Heart Rate": 144,
                "Min Heart Rate": 55,
              },
            ],
          },
        },
      },
      {
        order: 5,
        type: 2,
        goalOrder: 1,
        stepNumber: 4,
        title: "Power Recommendations",
        dbTitle: "analysis",
        description: `For Mentor360, Terra will enable enhanced customization and course recommendation options. For example, with Terra's data, you will be able to detect when a user's sleep quality has declined and recommend checking out your "Before Bed Habits" resource. Or, your mentors will be able to view user health data to offer tailored advice, such as meditation exercises when stress levels are high.`,
        content: {
          type: 4, // ScreenshotOverlay
          backgroundImage: "https://i.ibb.co/0htjvRt/IMG-5276.jpg",
          header: `"Health Data Recommendation"`,
          subHeader: `"Based on your recent sleep quality, we suggest you try our "Before Bed Habits" course!"`,
        },
      },
      {
        order: 7,
        type: 4,
        title: "All Done!",
        description: `Health data for your users comes from multiple sources, such as Garmin, Fitbit and Oura, in different forms and technologies. We make sense of data, and give you access to it in a structured manner, no matter the source.`,
      },
    ], // FIXME: remove and set to null for prod
    selectedTab: {
      index: 0,
      type: "auto",
    },
    gradients: genGradients(7),
  }
);

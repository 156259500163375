import React from "react";
import { StyleSheet } from "react-native";
import Endpoint from "./Endpoint/Endpoint";
import Select from "./Select/Select";
import Connect from "./Connect/Connect";
import VisualTrigger from "./VisualTrigger/VisualTrigger";
import ScreenshotOverlay from "./ScreenshotOverlay/ScreenshotOverlay";

const StepComponent = ({
  order,
  stepContent,
  scrollRef,
  scrollDown,
  isScrolling,
}) => {
  switch (stepContent.content.type) {
    case 0:
      return (
        <Endpoint
          order={order}
          stepContent={stepContent}
          scrollRef={scrollRef}
          scrollDown={scrollDown}
          isScrolling={isScrolling}
        />
      );
    case 1:
      return <Select order={order} stepContent={stepContent} />;
    case 2:
      return <Connect order={order} stepContent={stepContent} />;
    case 3:
      return (
        <VisualTrigger
          order={order}
          stepContent={stepContent}
          scrollRef={scrollRef}
          scrollDown={scrollDown}
          isScrolling={isScrolling}
        />
      );
    case 4:
      return <ScreenshotOverlay order={order} stepContent={stepContent} />;
  }
};

const styles = StyleSheet.create({});

export default StepComponent;

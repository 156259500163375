import React, { useContext, useState, useEffect, useRef } from "react";
import { StyleSheet, View, Image, useWindowDimensions } from "react-native";
import { Text } from "@geist-ui/react";
import { Context as PlanContext } from "@context/PlanContext";
import OverviewContent from "./OverviewContent";
import colors from "@res/colors";
import fonts from "@res/fonts";
import RemoteImage from "../../../../../components/RemoteImage";

const Overview = () => {
  const {
    state: { plan },
  } = useContext(PlanContext);

  const letterText = plan.letter.split("\n").map((elem) => {
    return (
      <Text
        key={`${elem.substring(0, 5)}`}
        style={{ color: plan.company.darkColor }}
        font="16px"
      >
        {elem}
      </Text>
    );
  });

  return (
    <View style={{ paddingTop: 50 }}>
      <View style={styles.container}>
        <View style={styles.letterContainer}>
          {plan.prospectLogo != null ? (
            <RemoteImage desiredWidth={125} uri={plan.prospectLogo} />
          ) : (
            <Text
              style={{
                color: plan.company.lightColor,
                fontFamily:
                  plan.company.headingFont != null
                    ? plan.company.headingFont
                    : null,
              }}
              font="20px"
              b
            >
              {plan.prospectCompany}
            </Text>
          )}
          <View style={{ marginVertical: 6 }} />
          <Text
            style={{
              color: "#002626",
              fontFamily:
                plan.company.headingFont != null
                  ? plan.company.headingFont
                  : null,
            }}
            font="26px"
            b
          >
            Hey, {plan.prospectCompany} team.
          </Text>
          <View style={{ marginVertical: 10 }} />
          {letterText}
        </View>

        <View
          style={[
            styles.overviewContainer,
            {
              background:
                "linear-gradient(" +
                120 +
                "deg, " +
                "#262626" +
                ", " +
                plan.company.darkColor +
                ")",
            },
          ]}
        >
          <OverviewContent />
        </View>
      </View>
      <View
        style={{
          width: "100%",
          alignSelf: "center",
          paddingHorizontal: 50,
          paddingVertical: 20,
          marginTop: 150,
          borderRadius: 10,
          background:
            "linear-gradient(" +
            120 +
            "deg, " +
            plan.company.darkColor +
            ", " +
            "#0f0f0f" +
            ")",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <View style={{ width: "60%", paddingVertical: 30 }}>
          <Text
            style={{
              color: "white",
              paddingBottom: 0,
              marginBottom: 0,
              fontFamily:
                plan.company.headingFont != null
                  ? plan.company.headingFont
                  : null,
            }}
            h1
          >
            {plan.prospectCompany}'s Test Drive
          </Text>
          <Text style={{ color: "white", opacity: 0.65 }} p>
            This page is already integrated with the {plan.company.name} API, so
            go ahead and take our API for a spin!
          </Text>
        </View>
        <View>
          <Image
            source={require("../../../../../../assets/images/rocket-dynamic-clay.png")}
            style={{
              height: 200,
              width: 200,
              alignSelf: "center",
            }}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
    alignItems: "center",
  },
  letterContainer: {
    width: "38%",
  },
  overviewContainer: {
    width: "50%",
    padding: 50,
    maxWidth: 500,
    borderRadius: 5,
  },
  background: {
    position: "absolute",
    borderRadius: 5,
    left: 0,
    right: 0,
    top: 0,
    height: "100%",
    zIndex: -1,
  },
});

export default Overview;
